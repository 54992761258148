import React from 'react';
import { Footer } from '../Components/Footer';
import { Experience } from '../Components/About/Experience';

export const About = () => {    
    return(
        <>
        <div className='h-max'>
            {/* <LightFade /> */}
            
            <div className='pt-20 ml-8 mb-8 lg:mb-10 gap-2 lg:gap-4 lg:w-2/3 w-10/12 lg:ml-auto lg:mr-auto flex flex-col items-center lg:items-stretch lg:text-center'>
                <h1 className='font-500 text-4xl lg:text-6xl lg:text-center text-white mt-4 opacity-90 w-full dropShadow'>about me.</h1>
                <h1 className='font-200 text-lg lg:text-xl text-white mt-1 opacity-50 w-full dropShadow'>I am a third year Computer Science and Interactive Technology student at Simon Fraser University. My interests and expertise lie in front-end development with a focus on web and mobile applications. Keep scrolling to find out more about my jouney, professional experience, and the technologies I know.</h1>
            </div>

            <Experience />
            
            <Footer />
        </div>
        </>
    )
}
