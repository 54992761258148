import { Link } from 'react-router-dom';
import algorithmly from '../../images/algorithmlyImage.png';
import toDoApp from '../../images/toDoImage.png';
import marioGame from '../../images/marioGame.png';
import gardenSimulator from "../../images/gardenSimulator.png";
import pharmabotics from "../../images/pharmabotics3.png";
import sfurobotsoccerimage from "../../images/sfurobotsoccerimage.png";
import { FaArrowRight } from "react-icons/fa";

export const ProjectsHome = () => {
  const projects = [
    {
      name: "Pharmabotics",
      projectYear: "IAT 267 Project, '24",
      projectDescription: "ReactJS, NodeJS, MongoDB, C++, Arduino Uno",
      image: pharmabotics,
      id: "pharmabotics",
      link: "/projects/pharmabotics",
    },
    {
      name: "SFU Robot Soccer Website",
      projectYear: "Professional Project, '24",
      projectDescription: "ReactJS, Google Firebase, Figma",
      image: sfurobotsoccerimage,
      id: "sfurobotsoccerimage",
      link: "/projects/sfurobotsoccerwebsite",
    },
    {
      name: "Garden Simulation Game",
      projectYear: "IAT 267 Project, '24",
      projectDescription: "Java",
      image: gardenSimulator,
      id: "gardenSimulator",
      link: "/projects/gardenSimulator",
    },
    {
      name: "Algorithmly",
      projectYear: "Personal Project, '23",
      projectDescription: "ReactJS, Google Firebase",
      image: algorithmly,
      id: "algorithmly",
      link: "/projects/algorithmly",
    },
    // {
    //     name: "Mario Shooter Game",
    //     projectYear: "School Project, '24",
    //     projectDescription: "Build a game using Javascript P5.JS library for IAT 167 course.",
    //     image: marioGame,
    //     id: 'marioGame',
    //     link: "/projects/marioGame"
    // },
  ];

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="pt-0 lg:flex-row flex-col flex gap-20 overflow-x-hidden lg:overflow-x-scroll no-scrollbar lg:py-16 px-6 lg:px-20">
      {projects.map((project, index) => (
        <div
          className="flex-shrink-0 flex justify-center mb-20 overflow-hidden items-center projectCardHomeBorder fadeUpAnimation"
          key={index}
        >
          <Link onClick={scrollTop} to={project.link}>
            <div className="projectCardHome px-11 py-10 ml-auto mr-auto flex flex-col justify-between">
              <div className="flex justify-between">
                <div>
                  <h1 className="font-300 text-3xl text-opacity-80 dropShadow text-white">
                    {project.name}
                  </h1>
                  <div className="flex mt-3 lg:mt-0 flex-col lg:flex-row gap-2 lg:items-center">
                    <h4 className="font-200 text-lg text-white text-opacity-70">
                      {project.projectYear}
                    </h4>
                    <div className="h-0.5 hidden lg:block w-5 bg-white bg-opacity-30"></div>
                    <h4 className="font-200 text-md text-white text-opacity-30">
                      {project.projectDescription}
                    </h4>
                  </div>
                </div>
                <div className="relative lg:mr-4 lg:mt-4">
                  <h3 className="text-white text-2xl dropShadow font-300">→</h3>
                </div>
              </div>

              <img
                alt="project"
                id={project.id}
                className="mt-8 lg:mt-14 opacity-70"
                src={project.image}
              />
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};