export const FutureEnhancements = () => {
    return(
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
            <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
                <div>
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Future Enhancements</h1>
                    
                    <div className="flex mt-4 lg:mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow'>Implimment the user authentication</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Right now there is no user authentication, so that will be the next step once the CRUD functions are all implimented</div>
                    </div>
                </div>
                <div className="mt-4 lg:mt-16">                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 lg:mt-1 w-max dropShadow'>Add the functionality for editing tasks</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>For this I will need to learn how to change a "div" in React Native into a text input so its can be updated in the same place it shows the data</div>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/2 h-full flex overflow-x-hidden lg:mt-5 justify-between flex-col text-left lg:p-8">
                <div>
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow'>Connect to the back end data base</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Right now the data is all manual hand written on the front end so I can get the functionality working, I need to connect to the API end points</div>
                    </div>
                </div>
            </div>
        </div>
    )
}