import React from 'react';
import { ProjectsHome } from '../Components/Home/ProjectsHome';
import { Footer } from '../Components/Footer';

export const Projects = () => {    
    return(
        <>
        <div className='h-max'>

            <div className='pt-20 ml-8 mb-8 lg:mb-0 gap-2 lg:gap-4 lg:w-1/2 w-10/12 lg:ml-auto lg:mr-auto flex flex-col items-center lg:items-stretch lg:text-center'>
                <h1 className='font-500 text-4xl lg:text-6xl lg:text-center text-white mt-4 opacity-90 w-full dropShadow'>projects.</h1>
                <h1 className='font-200 text-lg lg:text-xl text-white mt-1 opacity-40 w-full dropShadow'>These are all of the projects I have built over the past year using various tools; some are personal projects and others are for school, and work.</h1>
            </div>

            
            <ProjectsHome />
            

            <Footer />
        </div>
        </>
    )
}
