import pharmabotics1 from "../../images/pharmabotics1.png";
import pharmabotics2 from "../../images/pharmabotics2.png";
import robotsoccer4 from "../../images/robotsoccer4.png";
import video from "../../images/232ef0eea8fa4a55b43b8afe76002cc4.mp4";
import video2 from "../../images/387b85c8b45f4d85b422464ab5dc239f.mp4";

export const Features = () => {
  return (
    <div className="flex flex-col lg:flex-col gap-0 mt-28 pt-10 lg:mt-8 lg:px-16">
      <h1 className="font-300 text-sm text-white opacity-90 text-left lg:ml-8 w-full dropShadow">
        Features
      </h1>

      <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
        <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
          <div>
            <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
              Doctor's Screen
            </h1>

            <div className="flex mt-1 flex-col">
              <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                This is a page only accessable via doctor fingerprint. A doctor
                is able to scan their fingerprint and will gain access to this
                page in order to see all their patients and when patients need
                to take more medication. Along the side we will also be able to
                see all the patients who need to take their medication. These
                will be here until that patient takes their medication
              </div>
            </div>
          </div>
        </div>
        <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
          <img
            className="image opacity-100 rounded-b-lg"
            src={pharmabotics1}
            alt="Algorithmly Website"
          />
          <div
            className="fade-overlay absolute bottom-0 left-0 w-full h-full "
            style={{
              background:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
            }}
          ></div>
        </div>
      </div>

      <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
        <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
          <div>
            <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
              Add New Patients
            </h1>

            <div className="flex mt-1 flex-col">
              <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                This page allows the doctors to add new patients to the system
                when patients come into the care home/hospital, etc. They can
                control how often, how many and which exact medicine the patient
                will be taking. They will also enroll the patients fingerprint
                via the sensor.
              </div>
            </div>
          </div>
        </div>
        <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
          <img
            className="image opacity-100 rounded-b-lg"
            src={pharmabotics2}
            alt="Algorithmly Website"
          />
          <div
            className="fade-overlay absolute bottom-0 left-0 w-full h-full "
            style={{
              background:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
            }}
          ></div>
        </div>
      </div>

      <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
        <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
          <div>
            <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
              Sensors
            </h1>

            <div className="flex mt-1 flex-col">
              <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                This project had a variety of sensors, the first and most
                important one being the finger print sensor. This was used for
                identification and security. Only the doctor is able to open the
                compartment for refilling medication, and only the doctor is
                able to see all the patients and their medication information.
                Secondly, we used a proximity sensor in order to detect when the
                patient has placed their hand inside of the dispensing area.
                Finally, we used a pressure senser (FSR) in order to allow the
                doctor to hold and open the compartment where the medication is,
                or to press and close the compartment.
              </div>
            </div>
          </div>
        </div>
        <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
          <video
            className="w-full h-full rounded-xl object-cover"
            controls
            autoPlay
            loop
            muted
            onVolumeChange={(e) => {
              if (!e.target.muted) {
                e.target.muted = true;
              }
            }}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <div
            className="fade-overlay absolute bottom-0 left-0 w-full h-full "
            style={{
              background:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
            }}
          ></div> */}
        </div>
      </div>

      <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
        <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
          <div>
            <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
              Doctor Medication Refilling
            </h1>

            <div className="flex mt-1 flex-col">
              <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                We used a force sensor reading pressure sensor (FSR) in order to
                allow the doctor to open and close the compartment where the
                medicaiton is, in order to refill the system.
              </div>
            </div>
          </div>
        </div>
        <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
          <video
            className="w-full h-full rounded-xl object-cover"
            controls
            autoPlay
            loop
            muted
            onVolumeChange={(e) => {
              if (!e.target.muted) {
                e.target.muted = true;
              }
            }}
          >
            <source src={video2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <div
            className="fade-overlay absolute bottom-0 left-0 w-full h-full "
            style={{
              background:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
            }}
          ></div> */}
        </div>
      </div>
    </div>
  );
};
