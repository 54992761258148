import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import { Navbar } from './Components/Navbar';
import { About } from './Pages/About';
import { Algorithmly } from './Pages/Algorithmly';
import { Contact } from './Pages/Contact';
import { Home } from './Pages/Home';
import { Projects } from './Pages/Projects';
import { SFURobotSoccer } from './Pages/SfuRobotSoccer';
import { ToDoApp } from './Pages/ToDoApp';
import { Analytics } from "@vercel/analytics/react"
import { Pharmabotics } from "./Pages/Pharmabotics";
import { GardenSimulator } from "./Pages/GardenSimulator";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="app">
      <Router>
        <AppContent />
      </Router>
    </div>
  );
}

function AppContent() {
  const [isSub, setIsSub] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsSub(
      location.pathname.includes("/projects/algorithmly") ||
        location.pathname.includes("/projects/toDoApp") ||
        location.pathname.includes("/projects/gardenSimulator") ||
        location.pathname.includes("/projects/pharmabotics") ||
        location.pathname.includes("/projects/sfurobotsoccerwebsite")
    );
  }, [location]);

  return (
    <>
      {!isSub && <Navbar />}
      <Analytics />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects/algorithmly" element={<Algorithmly />} />
        <Route
          path="/projects/sfurobotsoccerwebsite"
          element={<SFURobotSoccer />}
        />
        <Route path="/projects/toDoApp" element={<ToDoApp />} />
        <Route path="/projects/pharmabotics" element={<Pharmabotics />} />
        <Route path="/projects/gardenSimulator" element={<GardenSimulator />} />
      </Routes>
    </>
  );
}

export default App;
