export const FutureEnhancements = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-4 lg:gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
      <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
        <div>
          <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
            Future Enhancements
          </h1>

          <div className="flex mt-4 lg:mt-1 flex-col">
            <div className="font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow">
              Custom Backend and Database
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              I would like to use NodeJS and MongoDB in order to create a full
              stack website where I gain control over the backend server-side as
              well as the database for storing form data and other objects of
              data from the site.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
