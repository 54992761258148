export const Overview = () => {
    return(
        <div className="flex flex-col lg:flex-row items-center gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
            <div className="lg:w-1/2 h-full flex flex-col text-left lg:justify-center lg:p-10">
                <div>
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Overview & Purpose</h1>
                    
                    <div className="">
                        <div className='font-100 text-lg text-white mt-2 opacity-40 lg:w-full dropShadow'>This is a mobile application build to track your to do list and tasks. The purpose for this application was to learn and practive mobile development using React Native; as well as, to improve my skills in full stack developent and learn TypeScript. I wanted to try and learn React Native for cross platform development and I wanted to learn TypeScript for strictly developed applications.</div>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
                <div>
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>My Role</h1>
                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow'>Developer & Designer</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Designed the UI and did some UX research in order to understand how to lay out mobile interfaces as this was my first full mobile application design. I also developed front-end and back-end of this application. Using node.js and creating my own API endpoints and sending data to a MongoDB database.</div>
                    </div>
                </div>
                <div className="mt-16">
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Timeline & Status</h1>
                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow'>In Progress</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>I began developing this application in early April 2024 and have been picking away at little funtions over time.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}