export const Challenges = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
      <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
        <div>
          <h1 className="font-300 text-sm mb-2 text-white opacity-90 w-full dropShadow">
            Challenges & Solutions
          </h1>

          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-white opacity-90 mt-1 w-max dropShadow">
              Automated Water Dispenser
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              Initially we were planning on building a water dispensing unit
              into our full system, using the force pressure sensor and a DC
              motor. However, while we were testing our dispensing system we
              realized that the motors we had were not strong enough to pull up
              the flap of the water nozzle. We then attempted to build it with a
              water bottle that would simply get lifted, pour water then get
              placed back down. However, due to the inaccuracy of the DC motor
              we kept getting over pour and spillage. We decided before our
              sensor gets totally destroyed lets take another route and hence we
              settled on an automated lid opening compartment for refilling
              medication.
            </div>
          </div>
        </div>
        <div className="mt-16">
          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-white opacity-90 mt-1 w-max dropShadow">
              Connecting Arduino to NodeJS
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              We struggled at first connecting the NodeJS server to the Arduino
              application in order to send and receive commands. After reading
              enough documentation, scrolling countless pages on Stackoverflow
              we finally realized we needed to upload the code to the
              microprocessor, terminate the Arduino application and then run the
              NodeJS server in order to connect the microprocessor to the NodeJS
              application.
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 h-full flex overflow-x-hidden lg:mt-5 justify-between flex-col text-left lg:p-8">
        <div>
          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-white opacity-90 mt-1 w-max dropShadow">
              Getting DC Motors Spinning
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              We spent endless amounts of time at first trying to simply get two
              DC motors spinning; we did not know initially we had to use a
              motor driver, but once we got that in place and got all the wiring
              correct it worked perfect. The second issue we ran into with the
              motors was that they are not precise. Each time we wanted to have
              our 3D printed mechanism spin and pick up a pill, there was no way
              of making the motor spin 360 degrees smooth and slow multiple
              times. In order to resolve this we had it so the motor spun just
              more than enough of one rotation using the PWM and manual time
              setting to ensure at least one medication dropped but not two.
            </div>
          </div>
        </div>
        <div>
          <div className="flex mt-12 flex-col">
            <div className="font-200 text-lg text-white opacity-90 mt-1 w-max dropShadow">
              Finger Print Sensor Data
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              The fingerprint sensor took a lot of patience. Initially we were
              trying to extract the data from the sensor and then save it with
              the user object in the MongoDB database. However, after days and
              days of trying to extract the data we found out the data is not
              extractable off the sensor we had bought. We then resorted to
              resetting the sensor, more stackoverflow and documentation reading
              happened for this… And then we were able to manually sync the ID
              from the user and print on the sensor to the data in the DB.
              Essentially, what was happening is the sensor would check for a
              match on its own data storage, return us back the ID of that print
              and we would use that ID to then search through the MongoDB for a
              user with that ID and then pass it back to our ReactJS.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
