export const FutureEnhancements = () => {
    return(
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
            <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
                <div>
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Future Enhancements</h1>
                    
                    <div className="flex mt-4 lg:mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow'>Add More Quizzes</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>At some point each algorithm will have its own quiz and some with more than the current 5 questions</div>
                    </div>
                </div>
                <div className="mt-4 lg:mt-16">                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 lg:mt-1 w-max dropShadow'>Add User Authentication</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Set up authentication so that users can have their own personal accounts and log in, and register for the application</div>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/2 h-full flex overflow-x-hidden lg:mt-5 justify-between flex-col text-left lg:p-8">
                <div>
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow'>Set Up a Backend</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>On top of the authentication, I want to set up a back-end using Node.js, Express, and MongoDB in order for the saving of quizzes and best scores to be enabled</div>
                    </div>
                </div>
                <div className="mt-4 lg:mt-16">
                    <div className="flex lg:mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 lg:mt-1 w-max dropShadow'>Leaderboards</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>It would be cool to have some sort of leaderboards using the data from the DB; this would be good for user engagment and would encourage healthy competition</div>
                    </div>
                </div>
            </div>
        </div>
    )
}