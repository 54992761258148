import React from 'react';
import { ReactP5Wrapper } from 'react-p5-wrapper';
import { Footer } from '../Components/Footer';
import Sketch from '../Components/Sketch';

export const Contact = () => {    
    return(
        <>
        <div className='h-max'>
            <div className='pt-96 pb-96 flex justify-center'>
                <h1 className='text-white font-500'>CONTACT PAGE</h1>
            </div>

                        
            

            <Footer />
        </div>
        </>
    )
}
