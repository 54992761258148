import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from '../images/logoWebsite.svg';

export const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [barPosition, setBarPosition] = useState(0);

    useEffect(() => {
        // Update barPosition based on the current location
        if (location.pathname === "/") {
            setBarPosition(0);
        } else if (location.pathname === "/projects") {
            setBarPosition(0.33);
        } else if (location.pathname === "/about") {
            setBarPosition(0.66);
        } else if (location.pathname === "/contact") {
            setBarPosition(1);
        }
    }, [location]);

    const shiftOne = () => {
        setBarPosition(0);
        window.scrollTo(0, 0);
        navigate('/');
    };

    const shiftTwo = () => {
        setBarPosition(0.33); 
        window.scrollTo(0, 0);
        navigate('/projects');
    };

    const shiftThree = () => {
        setBarPosition(0.66); 
        window.scrollTo(0, 0);
        navigate('/about');
    };
    
    const shiftFour = () => {
        setBarPosition(1); 
        window.scrollTo(0, 0);
        navigate('/contact');
    };

    return (
      <>
        <div className="flex fixed w-full justify-center z-40 top-4">
          <div className="flex gap-3 lg:gap-4 navContainer px-2.5 py-2 rounded-full">
            <button
              onClick={shiftOne}
              className={`px-4 py-2 navButton rounded-full ${
                location.pathname === "/" && "bg-white bg-opacity-10"
              }`}
            >
              <p className="font-300 text-white">
                <img className="w-6" alt="Logo" src={logo}></img>
              </p>
            </button>
            <button
              onClick={shiftTwo}
              className={`px-4 py-2 navButton rounded-full ${
                location.pathname === "/projects" && "bg-white bg-opacity-10"
              }`}
            >
              <p className="font-300 text-white">projects</p>
            </button>
            <button
              onClick={shiftThree}
              className={`px-4 py-2 navButton rounded-full ${
                location.pathname === "/about" && "bg-white bg-opacity-10"
              }`}
            >
              <p className="font-300 text-white">about</p>
            </button>
            {/* <button onClick={shiftFour} className='px-4 py-2 navButton rounded-full'><p className='font-300 text-white'>contact</p></button> */}
          </div>
        </div>
      </>
    );
}
