import { Footer } from "../Components/Footer";
import { useRef } from 'react';
import { Link } from "react-router-dom";
import toDoImage from '../images/toDoImage.png';
import { Overview } from "../Components/ToDoApp/Overview";
import { Technologies } from "../Components/ToDoApp/Technologies";
import { Features } from "../Components/ToDoApp/Features";
import { FutureEnhancements } from "../Components/ToDoApp/FutureEnhancements";

export const ToDoApp = () => {
    const overviewRef = useRef(null);
    const featuresRef = useRef(null);
    const technologiesRef = useRef(null);
    const challengesRef = useRef(null);
    const codeRef = useRef(null);
    const enhancementsRef = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    return(
        <div className='h-max w-screen overflow-x-hidden'>

            <div className="toDoCircle"></div>


            <div className="projectBackBorder fixed flex justify-center items-center top-8 left-8 lg:top-10 lg:left-10 z-20">
                <Link onClick={scrollTop} to="/projects"><button className="text-white text-md flex justify-center items-center gap-2 font-300  text-opacity-40 projectBack"><h3>←</h3> Back</button></Link>
            </div>

            <div className="w-max h-max hidden lg:flex flex flex-col lg:flex-col z-10 fixed items-start gap-4 top-28 right-12">
                <h3 className="text-white font-500 text-sm text-opacity-60">Contents</h3>
                <button onClick={() => scrollToSection(overviewRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out">Overview</button>
                <button onClick={() => scrollToSection(featuresRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out">Features</button>
                <button onClick={() => scrollToSection(technologiesRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out">Technologies</button>
                <button onClick={() => scrollToSection(challengesRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out text-left">Challenges <br /> & Solutions</button>
                <button onClick={() => scrollToSection(codeRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out">Code</button>
                <button onClick={() => scrollToSection(enhancementsRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out text-left">Future <br /> Enhancements</button>
            </div>

            <div className="w-screen background-100 example block lg:hidden z-10 h-32 flex fixed items-center gap-12 top-0 px-8 overflow-x-auto pt-20">
                <button onClick={() => scrollToSection(overviewRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out">Overview</button>
                <button onClick={() => scrollToSection(featuresRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out">Features</button>
                <button onClick={() => scrollToSection(technologiesRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out">Technologies</button>
                <button onClick={() => scrollToSection(challengesRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out text-left">Challenges <br /> & Solutions</button>
                <button onClick={() => scrollToSection(codeRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out">Code</button>
                <button onClick={() => scrollToSection(enhancementsRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out text-left">Future <br /> Enhancements</button>
            </div>
            
            <div className='pt-28 lg:pt-4 ml-8 mb-8 lg:mb-10 gap-2 lg:gap-4 w-10/12 lg:ml-auto lg:mr-auto flex flex-col items-center lg:items-stretch lg:text-center'>
                <h1 className='font-500 text-4xl lg:text-6xl lg:text-center text-white mt-14 lg:mt-4 opacity-90 w-full dropShadow'>to-do application</h1>
                <h1 className='font-200 text-lg lg:text-xl text-white mt-1 opacity-40 w-full dropShadow'>This is a mobile application for people to track their to do lists and stay on top of their days.</h1>
                
                <div className="flex mr-auto mt-2 lg:ml-auto gap-20">
                    <a href="https://github.com/harjotsk03/toDoApp" rel="noreferrer" target="_blank"><button className="text-white dropShadow font-400 text-opacity-70 hover:text-opacity-100 transition duration-300 ease-in-out">GitHub ↗</button></a>
                </div>

                <div className="image-container lg:w-10/12 mx-auto relative mt-3 rounded-b-3xl"
                data-aos="fade-up"
                data-aos-offset="200"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true"
                >
                    <img className="image lg:w-1/3 ml-auto mr-auto opacity-80 rounded-b-3xl" src={toDoImage} alt="Algorithmly Website" />
                    <div className="fade-overlay absolute bottom-0 left-0 w-full h-full rounded-3xl" style={{background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), #0b0b0b)'}}></div>
                </div>


                <div ref={overviewRef} className="dummy-section h-max">
                    <Overview />
                </div>

                <div ref={featuresRef} className="dummy-section h-max">
                   {/* <Features /> */}
                </div>

                <div ref={technologiesRef} className="dummy-section h-max">
                    <Technologies />
                </div>

                <div ref={challengesRef} className="dummy-section h-max">
                </div>

                <div ref={codeRef} className="dummy-section h-max">
                    
                </div>

                <div ref={enhancementsRef} className="dummy-section h-max">
                    <FutureEnhancements />
                </div>

            </div>
            
            <Footer />
        </div>
    )
}