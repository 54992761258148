export const Technologies = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
      <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
        <div>
          <h1 className="font-300 text-sm mb-2 text-white opacity-90 w-full dropShadow">
            Technologies & Tech Stack
          </h1>

          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-blue-400 opacity-70 mt-1 w-max dropShadow">
              ReactJS
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              Used for developing the front-end of the application
            </div>
          </div>
        </div>
        <div className="mt-16">
          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-blue-200 opacity-70 mt-1 w-max dropShadow">
              Arduino IDE (C++)
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              Used for developing the code and application for the NodeJS server
              to communicate and handle outputs of motors and LEDS, and to read
              and pass inputs from sensors back to NodeJS.
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 h-full flex overflow-x-hidden lg:mt-5 justify-between flex-col text-left lg:p-8">
        <div>
          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-green-500 opacity-70 mt-1 w-max dropShadow">
              Node.JS
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              Used for developing the back end of the application, and create
              API end points for data passing to the Arduino application
            </div>
          </div>
        </div>
        <div>
          <div className="flex mt-12 flex-col">
            <div className="font-200 text-lg text-green-300 opacity-70 mt-1 w-max dropShadow">
              MongoDB
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              A NoSQL database used for storing objects for patients and
              doctors, with all their information for medication such as
              intervals, dosgages, next time for taking, etc.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
