import resumeIcon from "../../images/resumeIcon.png";
import resume from "../../images/HarjotSinghResume24Official (7).pdf";
import {
  AiFillFileText,
  AiFillGithub,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";

export const Hero = () => {
  const buttonData = [
    {
      icon: <AiFillLinkedin size={30} />,
      tooltip: "LinkedIn",
      key: "linkedin",
      link: "https://www.linkedin.com/in/harjotsingh7/",
    },
    {
      icon: <AiFillGithub size={30} />,
      tooltip: "GitHub",
      key: "github",
      link: "https://github.com/harjotsk03",
    },
    {
      icon: <AiFillFileText size={30} />,
      tooltip: "Resume",
      key: "resume",
      link: resume,
    },
    {
      icon: <AiFillInstagram size={30} />,
      tooltip: "Instagram",
      key: "instagram",
      link: "https://www.instagram.com/7harjotsk/",
    },
  ];

  const ButtonWithTooltip = ({ icon, tooltip, link }) => (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <div className="relative group inline-block" key={tooltip}>
        <div className="hidden lg:flex bg-white poppins-light bg-opacity-5 text-white px-2 py-1 text-xs rounded-lg absolute -top-8 left-1/2 transform -translate-x-1/2 transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100 group-hover:delay-500">
          {tooltip}
        </div>
        <button className="text-white opacity-40 p-3 rounded-lg hover:bg-white hover:bg-opacity-5 hover:opacity-90 transition duration-300 ease-in-out">
          {icon}
        </button>
      </div>
    </a>
  );

  return (
    <div className="pt-32 lg:pt-40 lg:flex-row h-screen lg:py-40 px-6 lg:px-0 fadeUpAnimation">
      <h1 className="font-500 text-7xl lg:text-center heroText text-white mt-20 opacity-90 lg:mt-8 mb-6 lg:mb-10 2xl:mb-12 2xl:mt-20 w-full dropShadow">
        harjot singh.
      </h1>
      <h1 className="font-200 lg:text-center text-lg text-opacity-40 lg:text-left lg:text-2xl text-white">
        BSc in Computer Science & Interactive Technology
      </h1>
      <h1 className="font-200 mt-0.5 lg:text-center text-lg text-opacity-20 lg:text-left lg:text-2xl text-white">
        Student at Simon Fraser University
      </h1>
      <h4 className="absolute heroTextBack font-500">harjot singh</h4>
      <div className="flex gap-4 mt-20 lg:justify-center">
        <div className="flex flex-row gap-2 mt-10 lg:mt-0">
          {buttonData.map(({ icon, tooltip, link, key }) => (
            <ButtonWithTooltip
              icon={icon}
              tooltip={tooltip}
              link={link}
              key={key}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
