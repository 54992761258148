export const Technologies = () => {
  return (
    <div className="flex flex-col lg:flex-row gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
      <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
        <div>
          <h1 className="font-300 text-sm mb-2 text-white opacity-90 w-full dropShadow">
            Technologies & Tech Stack
          </h1>

          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-orange-400 opacity-70 mt-1 w-max dropShadow">
              Java
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              The entire application was developed in Java 18. There is no
              database or other technologies used.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
