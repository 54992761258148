import sfuImage from '../../images/sfu.jpg';
import brunoImage from '../../images/bruno.jpeg';
import soccerImage from '../../images/soccer.jpeg';

export const Experience = () => {

    const experience = [
        {
            department: "Robot Soccer",
            company: "Simon Fraser University",
            role: "Director of Web Development",
            date: "04/24 - Present",
            description: "I designed, developed and maintain the website for SFU Robot Soccer. I developed the UI/UX for the website and then reveloped it with React.js, Typescript, and Google Firebase."
        },
        {
            department: "Computer Science Student Society",
            company: "Simon Fraser University",
            role: "Web Developer",
            date: "03/24 - Present",
            description: "I am part of a team helping to design and develop the CSSS website. I am focusing on the front-end development and user experience. My goal is to make the website easier to navigate and more user friendly."
        },
    ];

    const techSkills = [
      {
        name: "React.js",
        time: "12-18 months",
      },
      {
        name: "Node.js",
        time: "12-18 months",
      },
      {
        name: "TailwindCSS",
        time: "12-18 months",
      },
      {
        name: "REST APIs",
        time: "12-18 months",
      },
      {
        name: "Mongo DB",
        time: "12 months",
      },
      {
        name: "Figma",
        time: "6-12 months",
      },
      {
        name: "Google Firebase",
        time: "6-12 months",
      },
      {
        name: "Arduino",
        time: "3-6 months",
      },
      {
        name: "Flask",
        time: "3-6 months",
      },
      {
        name: "React Native",
        time: "3-6 months",
      },
    ];

    const languages = [
      {
        name: "Javascript",
        time: "12-18 months",
      },
      {
        name: "Java",
        time: "6-12 months",
      },
      {
        name: "Python",
        time: "6-12 months",
      },
      {
        name: "Typescript",
        time: "3-6 months",
      },
      {
        name: "C/C++",
        time: "0-3 months",
      },
    ];

    return (
      <div className="lg:flex-col lg:justify-center px-8 lg:px-40 lg:mt-20 fadeUpAnimation">
        <h2 className="font-300 text-2xl text-opacity-80 dropShadow text-white mb-4">
          Experience <span className="flashingGreen">•</span>
        </h2>

        <div className="flex flex-col lg:justify-between justify-center gap-10 mb-20">
          {experience.map((job, index) => (
            <div
              className="flex flex-col mt-4 lg:mt-0 lg:flex-row lg:justify-center lg:items-center lg:justify-center lg:gap-10"
              key={index}
            >
              <div className="lg:w-1/2 lg:py-10 px-0 h-max lg:justify-center flex flex-col">
                <h1 className="text-white font-300 text-4xl text-opacity-90">
                  {job.department}
                </h1>
                <h1 className="text-white font-100 text-xl text-opacity-40 mt-1">
                  {job.company}
                </h1>
              </div>

              <div className="lg:w-1/2 mt-4 lg:mt-0 lg:py-10 px-0 lg:text-left justify-center flex flex-col">
                <h1 className="text-white font-300 text-2xl text-opacity-90">
                  {job.role}
                </h1>
                <h1 className="text-white font-100 text-lg text-opacity-40 mt-1">
                  {job.date}
                </h1>
                <h1 className="text-white font-100 text-lg text-opacity-70 mt-1">
                  {job.description}
                </h1>
              </div>
            </div>
          ))}
        </div>

        <h2 className="font-300 text-2xl text-opacity-80 dropShadow text-white mb-8">
          Languages <span className="flashingGreen">•</span>
        </h2>

        <div className="w-10/12 lg:gap-14 gap-8 mb-24 flex flex-wrap justify-start items-start">
          {languages.map((skill, index) => (
            <div className="flex justify-center items-center ">
              <div className=" py-2 flex flex-col justify-between">
                <p className="text-white font-300 text-2xl text-opacity-90">
                  {skill.name}
                </p>
                <p className="text-white font-300 text-lg text-opacity-40">
                  {skill.time}
                </p>
              </div>
            </div>
          ))}
        </div>

        <h2 className="font-300 text-2xl text-opacity-80 dropShadow text-white mb-8">
          Technical Skills <span className="flashingGreen">•</span>
        </h2>

        <div className="w-10/12 lg:gap-14 gap-8 mb-24 flex flex-wrap justify-start items-start">
          {techSkills.map((skill, index) => (
            <div className="flex justify-center items-center ">
              <div className=" py-2 flex flex-col justify-between">
                <p className="text-white font-300 text-2xl text-opacity-90">
                  {skill.name}
                </p>
                <p className="text-white font-300 text-lg text-opacity-40">
                  {skill.time}
                </p>
              </div>
            </div>
          ))}
        </div>

        <h2 className="font-300 text-2xl text-opacity-80 dropShadow text-white mb-8">
          Personal Life <span className="flashingGreen">•</span>
        </h2>

        <div className="w-10/12 lg:w-11/12 lg:gap-14 gap-8 mb-20 flex flex-col">
          <div className="flex flex-col lg:flex-row">
            <div class="w-1/2 rounded-xl  mr-10 relative">
              <img
                src={sfuImage}
                class="personalImage absolute inset-0 w-full h-full object-cover rounded-xl"
                alt="SFU Image"
              ></img>
            </div>

            <div className="lg:w-1/2 flex justify-end ml-auto flex flex-col">
              <h3 className="text-white font-300 text-3xl text-opacity-90">
                Education
              </h3>
              <h4 className="text-white font-200 text-xl text-opacity-60 mt-1">
                In 2021 I started at SFU in Political Science. After taking a
                course related to data in politics, I found my passion for code
                through R Studio. <br></br> <br></br> Fast forward a year, and I
                was taking Into to Computer Science and Calculus I. Since that
                moment I have enjoyed every minuete of writting code and solving
                complex problems whether it be math, discrete mathematics,
                software development or user experience research. <br></br>
                <br></br>I started university in 2021, initially studying
                Political Science at SFU. I
              </h4>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 flex justify-start mr-auto flex flex-col">
              <h3 className="text-white font-300 text-3xl text-opacity-90">
                Early Years
              </h3>
              <h4 className="text-white font-200 text-xl text-opacity-60 mt-1">
                I was born in Huddersfield, England; however, I moved to Canada
                at the age of 6, and have been enjoying life ever since.
                <br></br>
                <br></br> Since the age of 3/4 years old I have been kicking a
                football in some shape or form. It was once I came to Canada
                where I was able to join an actual club and fulfill my dreams of
                playing football on a team. I played all through elementary and
                high scool and into my first year of university.
              </h4>
            </div>

            <div class="w-1/2 rounded-xl  ml-10 relative">
              <img
                src={soccerImage}
                class="personalImage absolute inset-0 w-full h-full object-cover rounded-xl"
                alt="SFU Image"
              ></img>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row">
            <div class="w-1/2 rounded-xl mr-10 relative">
              <img
                src={brunoImage}
                class="personalImage absolute inset-0 w-full h-full object-cover rounded-xl"
                alt="SFU Image"
              ></img>
            </div>

            <div className="lg:w-1/2 flex justify-end ml-auto flex flex-col">
              <h3 className="text-white font-300 text-3xl text-opacity-90">
                Hobbies
              </h3>
              <h4 className="text-white font-200 text-xl text-opacity-60 mt-1">
                On top of my love for playing football, I also enjoy watching
                it. If you are curious I have supported Manchester United since
                the day I could understand the concept of the sport. <br></br>
                <br></br>Other than soccer you can find me in the gym, sitting
                at my desk writing code for who knows what, or spending time
                with my 2 year old goldendoogle Bruno Singh.
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
}
