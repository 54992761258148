export const Overview = () => {
    return(
        <div className="flex flex-col lg:flex-row gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
            <div className="lg:w-1/2 h-full flex flex-col text-left lg:justify-center lg:p-10">
                <div>
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Overview & Purpose</h1>
                    
                    <div className="">
                        <div className='font-100 text-lg text-white mt-2 opacity-40 lg:w-full dropShadow'>This is the SFU Robot Soccer Club website. It is used for the clubs teams, their members and any one who wants to know more about the club and what they do. I took up the role as director of web development in April 2024 and have completly re-designed, and developed the new face of the SFU Robot Soccer website. I have implimented features to improve interactivity; whilst also improving UX and keeping in mind mobile users will make up majority of the visits to this site.</div>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
                <div>
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>My Role</h1>
                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow'>Developer & Designer</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Developed the front-end of the website as well as connecting it with Google Firebase for data storage. Designed the UI with major concern for UX and accessability.</div>
                    </div>
                </div>
                <div className="mt-16">
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Timeline & Status</h1>
                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow'>Near Completion</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Began developing this website in early April 2024 and will continue to maintain and develop small features when needed.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}