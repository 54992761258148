import robotsoccer1 from '../../images/robotsoccer1.png';
import robotsoccer2 from '../../images/robotsoccer2.png';
import robotsoccer3 from '../../images/robotsoccer3.png';
import robotsoccer4 from '../../images/robotsoccer4.png';

export const Features = () => {
    return (
      <div className="flex flex-col lg:flex-col gap-0 mt-28 pt-10 lg:mt-8 lg:px-16">
        <h1 className="font-300 text-sm text-white opacity-90 text-left lg:ml-8 w-full dropShadow">
          Features
        </h1>

        <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
          <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
            <div>
              <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
                Sponsorship Information Page
              </h1>

              <div className="flex mt-1 flex-col">
                <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                  In order to display all of the information regarding each
                  sponsorship package, I made this custom table that shows what
                  comes with each level of sponsorship. This table is completly
                  responsive as it works on both desktop and mobile.
                </div>
              </div>
            </div>
          </div>
          <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
            <img
              className="image opacity-100 rounded-b-lg"
              src={robotsoccer1}
              alt="Algorithmly Website"
            />
            <div
              className="fade-overlay absolute bottom-0 left-0 w-full h-full "
              style={{
                background:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
              }}
            ></div>
          </div>
        </div>

        <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
          <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
            <div>
              <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
                Teams and Executive Drop Downs
              </h1>

              <div className="flex mt-1 flex-col">
                <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                  On the Teams home page I have access to all the subteams in
                  SFU RS as well as custom drop down sections in order to see
                  the Executive Team, Directors, Alumni and other individuals
                  without having to see them all at once. This is good practice
                  for UX especially on mobile as it allows the user to choose
                  what they see and not have to scroll a bunch to see what they
                  are trying to see.
                </div>
              </div>
            </div>
          </div>
          <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
            <img
              className="image opacity-100 rounded-b-lg"
              src={robotsoccer2}
              alt="Algorithmly Website"
            />
            <div
              className="fade-overlay absolute bottom-0 left-0 w-full h-full "
              style={{
                background:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
              }}
            ></div>
          </div>
        </div>

        <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
          <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
            <div>
              <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
                About Page
              </h1>

              <div className="flex mt-1 flex-col">
                <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                  A simple but effective about section with some images, text
                  and I also implimented a timeline for the entire lifespan of
                  the club so people can see what month and year what was
                  achieved and what occured in the club at that time period
                </div>
              </div>
            </div>
          </div>
          <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
            <img
              className="image opacity-100 rounded-b-lg"
              src={robotsoccer3}
              alt="Algorithmly Website"
            />
            <div
              className="fade-overlay absolute bottom-0 left-0 w-full h-full "
              style={{
                background:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
              }}
            ></div>
          </div>
        </div>

        <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
          <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
            <div>
              <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
                Contact Page
              </h1>

              <div className="flex mt-1 flex-col">
                <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                  Again nice and simple, a contact page with all the social
                  media links as well as a form that gets emailed to the
                  executive team email every time someone submits their form.
                </div>
              </div>
            </div>
          </div>
          <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
            <img
              className="image opacity-100 rounded-b-lg"
              src={robotsoccer4}
              alt="Algorithmly Website"
            />
            <div
              className="fade-overlay absolute bottom-0 left-0 w-full h-full "
              style={{
                background:
                  "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
              }}
            ></div>
          </div>
        </div>
      </div>
    );
}