import algorithmly1 from '../../images/algorithmly1.png';
import algorithmly2 from '../../images/algorithmly2.png';
import algorithmly3 from '../../images/algorithmly3.png';

export const Features = () => {
    return(
        <div className='flex flex-col lg:flex-col gap-0 mt-28 pt-10 lg:mt-8 lg:px-16'>
            <h1 className='font-300 text-sm text-white opacity-90 text-left lg:ml-8 w-full dropShadow'>Features</h1>

            <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
                <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
                    <div>
                        <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Time Complexity & Description</h1>
                        
                        <div className="flex mt-1 flex-col">
                            <div className='font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow'>This feature allows the user to read and understand how the algorithm works, as well as, the best use cases for each algorithm. Moreover, understanding time complexity (Big O Notation) is crucial in computer science; hence, I added in the best and worst Big O times for each algorithm.</div>
                        </div>
                    </div>
                </div>
                <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
                        <img className="image opacity-100 rounded-b-lg" src={algorithmly1} alt="Algorithmly Website" />
                        <div className="fade-overlay absolute bottom-0 left-0 w-full h-full " style={{background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))'}}></div>
                </div>
            </div>


            <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
                <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
                    <div>
                        <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Pseudocode</h1>
                        
                        <div className="flex mt-1 flex-col">
                            <div className='font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow'>Understanding the code in english is the first step of being able to understand how the algorithm should be implimented into code. It is critical to understand how and what each line of code is doing in every algorithm; therefore, I decided to allow the user to learn the pseudocode and then they can take this and program the algorithm into any given language of their choice.</div>
                        </div>
                    </div>
                </div>
                <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
                        <img className="image opacity-100 rounded-b-lg" src={algorithmly2} alt="Algorithmly Website" />
                        <div className="fade-overlay absolute bottom-0 left-0 w-full h-full " style={{background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))'}}></div>
                </div>
            </div>



            <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
                <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
                    <div>
                        <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Quizzes</h1>
                        
                        <div className="flex mt-1 flex-col">
                            <div className='font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow'>I wanted to allow users to test their knowledge after spending some time learning the algorithms. Adding in simple 5 question quizzes allows for the user to do just that while not making them feel as though they are in a strict class. This feature is a fun, easy way to keep users engaged and encouraged to keep learning.</div>
                        </div>
                    </div>
                </div>
                <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
                        <img className="image opacity-100 rounded-b-lg" src={algorithmly3} alt="Algorithmly Website" />
                        <div className="fade-overlay absolute bottom-0 left-0 w-full h-full " style={{background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))'}}></div>
                </div>
            </div>

        </div>
    )
}