export const Technologies = () => {
    return(
        <div className="flex flex-col lg:flex-row gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
            <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
                <div>
                    <h1 className='font-300 text-sm mb-2 text-white opacity-90 w-full dropShadow'>Technologies & Tech Stack</h1>
                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-blue-400 opacity-70 mt-1 w-max dropShadow'>React Native & TypeScript</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Used for developing the front-end of the application</div>
                    </div>
                </div>
                <div className="mt-16">                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-blue-200 opacity-70 mt-1 w-max dropShadow'>Figma</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Used for designing the UI before implimentation, and used for UX research to understand UX and UI principles for mobile development</div>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/2 h-full flex overflow-x-hidden lg:mt-5 justify-between flex-col text-left lg:p-8">
                <div>
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-green-500 opacity-70 mt-1 w-max dropShadow'>Node.JS & TypeScript</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Used for developing the back end of the application, and create API end points for data passing</div>
                    </div>
                </div>
                <div>
                    <div className="flex mt-12 flex-col">
                        <div className='font-200 text-lg text-green-300 opacity-70 mt-1 w-max dropShadow'>MongoDB</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>The backend data base for storing, users, tasks, and other states of data for the application</div>
                    </div>
                </div>
            </div>
        </div>
    )
}