import { Link } from "react-router-dom"
import resume from "../images/HarjotSinghResume24Official (1).pdf";

export const Footer = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="w-full h-max pb-10 lg:px-40 lg:py-20 flex flex-col gap-10 justify-between lg:flex-row footer">
      <div className=" h-1/2 px-8 py-10 lg:py-0 lg:px-0 lg:h-full flex flex-col justify-between lg:w-1/3">
        <h3 className="font-300 text-white text-lg leading-loose lg:leading-normal">
          Harjot Singh. <br></br>
          <span className="text-opacity-30 text-white font-200">
            All rights reserved 2024 ©
          </span>
        </h3>
        <h3 className="font-200 text-white text-lg text-opacity-30 mt-10">
          Made with React.js, TailwindCSS; hosted on Vercel.
        </h3>
      </div>
      <div className=" h-1/2 text-right lg:h-full lg:w-1/2 flex flex-row justify-start ml-8 gap-20 lg:justify-end lg:gap-20">
        <div className="flex flex-col gap-1">
          <h2 className="text-left lg:text-right text-white font-200 text-opacity-50 mb-3">
            Pages
          </h2>
          <Link onClick={scrollTop} to="/">
            <h2 className="text-left lg:text-right text-white font-300 text-opacity-90">
              home
            </h2>
          </Link>
          <Link onClick={scrollTop} to="/projects">
            <h2 className="text-left lg:text-right text-white font-300 text-opacity-90">
              projects
            </h2>
          </Link>
          <Link onClick={scrollTop} to="/about">
            <h2 className="text-left lg:text-right text-white font-300 text-opacity-90">
              about
            </h2>
          </Link>
          {/* <Link to="/contact"><h2 className="text-left lg:text-right text-white font-300 text-opacity-90">contact</h2></Link>                     */}
        </div>
        <div className="flex flex-col gap-1">
          <h2 className="text-left lg:text-right text-white font-200 text-opacity-50 mb-3">
            Connect
          </h2>
          <a target="_blank" href="https://www.linkedin.com/in/harjotsingh7/">
            <h2 className="text-left lg:text-right text-white font-300 text-opacity-90">
              linkedin ↗
            </h2>
          </a>
          <a target="_blank" href="https://github.com/harjotsk03">
            <h2 className="text-left lg:text-right text-white font-300 text-opacity-90">
              github ↗
            </h2>
          </a>
          {/* <a href="" ><h2 className="text-left lg:text-right text-white font-300 text-opacity-90">discord ↗</h2></a> */}
          {/* <a href="" ><h2 className="text-left lg:text-right text-white font-300 text-opacity-90">instagram ↗</h2></a> */}
          <a href={resume} download>
            <h2 className="text-left lg:text-right text-white font-300 text-opacity-90">
              download resume ↗
            </h2>
          </a>
        </div>
      </div>
    </div>
  );
};