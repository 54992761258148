export const Technologies = () => {
    return(
        <div className="flex flex-col lg:flex-row gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
            <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
                <div>
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Technologies & Tech Stack</h1>
                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-blue-400 opacity-70 mt-1 w-max dropShadow'>React.js</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Used for developing the front-end of the application</div>
                    </div>
                </div>
                <div className="mt-16">                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-blue-200 opacity-70 mt-1 w-max dropShadow'>TailwindCSS</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Used for styling and creating responsive layout for all devices. Vanilla CSS was also used when neccissary for custom styling and animations</div>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/2 h-full flex overflow-x-hidden lg:mt-5 justify-between flex-col text-left lg:p-8">
                <div>
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-yellow-500 opacity-70 mt-1 w-max dropShadow'>Google Firebase</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Used for hosting the web application, as well as, for basic site analytics</div>
                    </div>
                </div>
                <div className="mt-16">
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-red-500 opacity-70 mt-1 w-max dropShadow'>Figma</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Used for designing the UI and UX of the application before development</div>
                    </div>
                </div>
            </div>
        </div>
    )
}