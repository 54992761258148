import garden1 from "../../images/garden1.png";
import garden2 from "../../images/garden2.png";
import garden3 from "../../images/garden3.png";

export const Features = () => {
  return (
    <div className="flex flex-col lg:flex-col gap-0 mt-28 pt-10 lg:mt-8 lg:px-16">
      <h1 className="font-300 text-sm text-white opacity-90 text-left lg:ml-8 w-full dropShadow">
        Features
      </h1>

      <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
        <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
          <div>
            <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
              Instructions Page
            </h1>

            <div className="flex mt-1 flex-col">
              <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                I used graphics and text to design an instructions page for
                users to read before playing the game. I used UX and UI design
                principles to make the screen understandable and intuitive.
              </div>
            </div>
          </div>
        </div>
        <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
          <img
            className="image opacity-100 rounded-b-lg"
            src={garden1}
            alt="Algorithmly Website"
          />
          <div
            className="fade-overlay absolute bottom-0 left-0 w-full h-full "
            style={{
              background:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
            }}
          ></div>
        </div>
      </div>

      <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
        <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
          <div>
            <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
              Selling Crops
            </h1>

            <div className="flex mt-1 flex-col">
              <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                This is the screen where once users have more than 12 of one
                crop they are able to access this screen. They can sell the
                crops they harvested and sell them for money. Users can only
                sell up to however many of that crop they have harvested.
              </div>
            </div>
          </div>
        </div>
        <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
          <img
            className="image opacity-100 rounded-b-lg"
            src={garden2}
            alt="Algorithmly Website"
          />
          <div
            className="fade-overlay absolute bottom-0 left-0 w-full h-full "
            style={{
              background:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
            }}
          ></div>
        </div>
      </div>

      <div className="flex flex-col lg:p-8 mt-3 lg:mt-4 lg:flex-col gap-3">
        <div className="lg:w-11/12 h-full flex overflow-x-hidden justify-between flex-col text-left">
          <div>
            <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
              Continuous Play
            </h1>

            <div className="flex mt-1 flex-col">
              <div className="font-100 text-lg text-white mt-1 opacity-40 w-full dropShadow">
                I have made it so that after selling the crops the user can
                choose to either restart the application or to continue the same
                simulation. Should the user continue it will update their money
                earned and continue adding on their previous not sold crops they
                harvested. They can also choose to restart at any point via
                "Help" and then restart.
              </div>
            </div>
          </div>
        </div>
        <div className="image-container h-max mt-3 lg:mt-4 lg:w-10/12 relative rounded-xl">
          <img
            className="image opacity-100 rounded-b-lg"
            src={garden3}
            alt="Algorithmly Website"
          />
          <div
            className="fade-overlay absolute bottom-0 left-0 w-full h-full "
            style={{
              background:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
