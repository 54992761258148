export const Overview = () => {
    return(
        <div className="flex flex-col lg:flex-row gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
            <div className="lg:w-1/2 h-full flex flex-col text-left lg:justify-center lg:p-10">
                <div>
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Overview & Purpose</h1>
                    
                    <div className="">
                        <div className='font-100 text-lg text-white mt-2 opacity-40 lg:w-full dropShadow'>This web application was built to aid people who are interested in learning simple search and sort algorithms through visualization. The reason I decided to develop this app was because in my first year of computer science I struggled to grasp the concept of certain algorithms and it always helped me to see things visually; hence, I thought an app where algorithms can be visually seen may aid others in their journey as well.</div>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
                <div>
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>My Role</h1>
                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow'>Developer & Designer</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Developing the front-end of the application and designing the UI</div>
                    </div>
                </div>
                <div className="mt-16">
                    <h1 className='font-300 text-sm text-white opacity-90 w-full dropShadow'>Timeline & Status</h1>
                    
                    <div className="flex mt-1 flex-col">
                        <div className='font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow'>In Progress</div>
                        <div className='font-100 text-lg text-white opacity-40 w-full dropShadow'>Began developing this app in December 2023 and will be continuing to add new elements as I have spare time</div>
                    </div>
                </div>
            </div>
        </div>
    )
}