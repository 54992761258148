import React from 'react';
import { Hero } from '../Components/Home/Hero';
import { ProjectsHome } from '../Components/Home/ProjectsHome';
import { Footer } from '../Components/Footer';

export const Home = () => {    
    return(
        <>
        <div className=''>
        
            <Hero />


            <div className='absolute bottom-6 lg:bottom-5 left-6 lg:left-0 lg:justify-center lg:w-full lg:bottom-14 flex gap-2 fadeDownAnimation'>
                <h4 className='font-300 text-white text-opacity-20'>scroll to explore</h4>
                <h4 className='arrowAnimation text-white text-opacity-20'>↓</h4>
            </div> 


            <ProjectsHome />

            <Footer />
        </div>
        </>
    )
}
