export const Overview = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
      <div className="lg:w-1/2 h-full flex flex-col text-left lg:justify-center lg:p-10">
        <div>
          <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
            Overview & Purpose
          </h1>

          <div className="">
            <div className="font-100 text-lg text-white mt-2 opacity-40 lg:w-full dropShadow">
              This is an automated medicine dispensing system that uses ReactJS
              as the user interface, connected to the server side that is built
              with NodeJS and Express. We used MongoDB as our NoSQL database
              that connects to both our server-side application and to our
              Arduino. We used an Arduino Uno microcontroller along with the
              Arduino IDE written in C++ in order to program the microcontroller
              based on actions and requests from the NodeJS application. This
              system is to be implemented into care homes, hospitals, and other
              locations where we can remove the use of nurses to simply provide
              medication to patients to allow them to spend time on other
              important tasks.
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
        <div>
          <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
            My Role
          </h1>

          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow">
              Developer & Builder
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              For this project I designed/developed the ReactJS application in
              order to have an easy to use interface that had strong UI concepts
              and features good UX principles in order to make the system as
              intuitive as possible. Furthermore, I also developed the NodeJS
              server side code in order to communicate with our client-side, the
              MongoDB objects, and with our Arduino application. I also worked
              on putting the C++ code for the sensors together in order to have
              connection and function between the Arduino Uno and the NodeJS
              server.
            </div>
          </div>
        </div>
        <div className="mt-16">
          <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
            Timeline & Status
          </h1>

          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow">
              Complete
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              This was a single semester course project that was started in May
              2024 and was completed by August 2024.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
