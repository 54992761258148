import { Footer } from "../Components/Footer";
import { useRef } from 'react';
import { Link } from "react-router-dom";
import sfurobotsoccerimage from '../images/sfurobotsoccerimage.png';
import { Overview } from "../Components/RobotSoccer/Overview";
import { Features } from "../Components/RobotSoccer/Features";
import { Technologies } from "../Components/RobotSoccer/Technologies";
import { Code } from "../Components/RobotSoccer/Code";
import { FutureEnhancements } from "../Components/RobotSoccer/FutureEnhancements";

export const SFURobotSoccer = () => {
  const overviewRef = useRef(null);
  const featuresRef = useRef(null);
  const technologiesRef = useRef(null);
  const challengesRef = useRef(null);
  const codeRef = useRef(null);
  const enhancementsRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="h-max w-screen overflow-x-hidden">
      <div className="robotCircle"></div>

      <div className="projectBackBorder fixed flex justify-center items-center top-8 left-8 lg:top-10 lg:left-10 z-20">
        <Link onClick={scrollTop} to="/projects">
          <button className="text-white text-md flex justify-center items-center gap-2 font-300  text-opacity-40 projectBack">
            <h3>←</h3> Back
          </button>
        </Link>
      </div>

      <div className=" h-max hidden lg:flex flex flex-col lg:flex-col z-10 fixed items-start gap-4 top-28 right-16 w-20">
        <h3 className="text-white font-500 text-sm text-opacity-60">
          Contents
        </h3>
        <button
          onClick={scrollTop}
          className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out"
        >
          Top
        </button>
        <button
          onClick={() => scrollToSection(overviewRef)}
          className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out"
        >
          Overview
        </button>
        <button
          onClick={() => scrollToSection(featuresRef)}
          className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out"
        >
          Features
        </button>
        <button
          onClick={() => scrollToSection(technologiesRef)}
          className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out"
        >
          Technologies
        </button>
        {/* <button onClick={() => scrollToSection(challengesRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out text-left">Challenges <br /> & Solutions</button> */}
        <button
          onClick={() => scrollToSection(enhancementsRef)}
          className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out text-left"
        >
          Future <br /> Enhancements
        </button>
      </div>

      <div className="w-screen background-100 example block lg:hidden z-10 h-32 flex fixed items-center gap-12 top-0 px-8 overflow-x-auto pt-20">
        <button
          onClick={scrollTop}
          className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out"
        >
          Top
        </button>
        <button
          onClick={() => scrollToSection(overviewRef)}
          className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out"
        >
          Overview
        </button>
        <button
          onClick={() => scrollToSection(featuresRef)}
          className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out"
        >
          Features
        </button>
        <button
          onClick={() => scrollToSection(technologiesRef)}
          className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out"
        >
          Technologies
        </button>
        {/* <button onClick={() => scrollToSection(challengesRef)} className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out text-left">Challenges <br /> & Solutions</button> */}
        <button
          onClick={() => scrollToSection(enhancementsRef)}
          className="font-300 text-opacity-30 text-white text-xs hover:text-opacity-100 transition duration-300 ease-in-out text-left"
        >
          Future <br /> Enhancements
        </button>
      </div>

      <div className="pt-28 lg:pt-4 ml-8 mb-8 lg:mb-10 gap-2 lg:gap-4 w-10/12 lg:ml-auto lg:mr-auto flex flex-col items-center lg:items-stretch lg:text-center">
        <h1 className="font-500 text-4xl lg:text-6xl lg:text-center text-white mt-14 lg:mt-4 opacity-90 w-full dropShadow">
          sfu robot soccer website
        </h1>
        <h1 className="font-200 text-lg lg:text-xl text-white mt-1 opacity-40 lg:w-2/3 ml-auto mr-auto dropShadow">
          This is the official website for the SFU Robot Soccer club. I joined
          the club as director of web development and designed & developed the
          site for the club.
        </h1>

        <div className="flex mr-auto mt-2 lg:ml-auto gap-20">
          <a href="https://sfurobotsoccer.com" rel="noreferrer" target="_blank">
            <button className="text-white dropShadow font-400 text-opacity-70 hover:text-opacity-100 transition duration-300 ease-in-out">
              Live Site ↗
            </button>
          </a>
          <a
            href="https://github.com/harjotsk03/"
            rel="noreferrer"
            target="_blank"
          >
            <button className="text-white dropShadow font-400 text-opacity-70 hover:text-opacity-100 transition duration-300 ease-in-out">
              GitHub ↗
            </button>
          </a>
        </div>

        <div className="image-container lg:w-10/12 mx-auto relative mt-3 rounded-b-3xl">
          <img
            className="image opacity-80 rounded-b-3xl"
            src={sfurobotsoccerimage}
            alt="SFU Robot Soccer Website"
          />
          <div
            className="fade-overlay absolute bottom-0 left-0 w-full h-full "
            style={{
              background:
                "linear-gradient(to bottom, rgba(255, 255, 255, 0), #0b0b0b)",
            }}
          ></div>
        </div>

        <div ref={overviewRef} className="dummy-section h-max">
          <Overview />
        </div>

        <div ref={featuresRef} className="dummy-section h-max">
          <Features />
        </div>

        <div ref={technologiesRef} className="dummy-section h-max">
          <Technologies />
        </div>

        <div ref={challengesRef} className="dummy-section h-max"></div>

        <div ref={enhancementsRef} className="dummy-section h-max">
          <FutureEnhancements />
        </div>
      </div>

      <Footer />
    </div>
  );
};