export const Overview = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center gap-10 mt-28 pt-10 lg:mt-14 lg:px-16">
      <div className="lg:w-1/2 h-full flex flex-col text-left lg:justify-center lg:p-10">
        <div>
          <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
            Overview & Purpose
          </h1>

          <div className="">
            <div className="font-100 text-lg text-white mt-2 opacity-40 lg:w-full dropShadow">
              This is a Gardening Simulation Game build with Java. This project
              uses Object Oriented Programming to create a clean, maintainable
              and understandable application. I used many OOP principles in this
              project such as, abstraction for classes, interfaces, inclusion
              and overriding polymorphism, inheritance, and encapsulation.
            </div>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2 h-full flex overflow-x-hidden justify-between flex-col text-left lg:p-8">
        <div>
          <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
            My Role
          </h1>

          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow">
              Developer & Designer
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              I developed this entire application from scratch using Java and
              OOP principles. I also designed the UI for the game with sprites
              and images found from OpenGameArt.org.
            </div>
          </div>
        </div>
        <div className="mt-16">
          <h1 className="font-300 text-sm text-white opacity-90 w-full dropShadow">
            Timeline & Status
          </h1>

          <div className="flex mt-1 flex-col">
            <div className="font-200 text-lg text-white opacity-70 mt-1 w-max dropShadow">
              Complete
            </div>
            <div className="font-100 text-lg text-white opacity-40 w-full dropShadow">
              This was a single semester course final project that was started
              in July 2024 and was completed by August 2024.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
