import React from 'react';
import { ReactP5Wrapper } from 'react-p5-wrapper';

const Sketch = () => {
  const setup = (p) => {
    p.createCanvas(400, 400);
  };

  const draw = (p) => {
    p.background(255);
    p.fill(255);
    p.rect(100, 100, 50, 50);
  };

  return <ReactP5Wrapper setup={setup} draw={draw} />;
};

export default Sketch;
